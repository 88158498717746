import React, { memo, useState, useEffect, useCallback } from "react"
import { useTranslation } from "next-i18next"

import { floor, get } from "lodash"
import anime from "animejs"
import { useRouter } from "next/router"
import Image from "next/image"
import ImagePriority from "@components/core/Image13"

import ModalResult from "@components/pages/power-block-game/ModalResult"
import { formatCommas, getFormatDate } from "@helpers/utils"
import { getJackpot } from "@api/powerBlockAPI"
import Countdown from "@components/core/CountDown"
import bannerPage from "../../../../public/images/banner-jackpot.png"

const TIME_ANIMATION = 3 * 1000
const INTERVAL_TIME = 10 * 1000

const format0 = (amount) => {
  return `000000${amount}`.slice(-9)
}

const generateTextNumber = (amount) => {
  let textEl = ""
  const text = formatCommas(format0(amount))
  const chars = text.split("")
  for (let i = 0; i < chars.length; i += 1) {
    const number = chars[i]
    textEl += `<div class='number number-${number}'></div>`
  }
  return textEl
}

// Making setInterval Declarative with React Hooks: https://overreacted.io/making-setinterval-declarative-with-react-hooks/
let amount = 0

const Banner = (props) => {
  const { t } = useTranslation()

  const { game = {}, dialedGames = [] } = props

  const runAnim = useCallback((newAmount, isFirst) => {
    const jackpotEl = document.getElementById("powerBlock_jackpot")
    const object = {
      amount
    }
    anime({
      targets: object,
      amount: newAmount,
      round: 1,
      easing: "linear",
      duration: isFirst ? TIME_ANIMATION : 200,
      update: () => {
        jackpotEl.innerHTML = generateTextNumber(object.amount)
      }
    })
    amount = newAmount
  }, [])

  const onGetJackpot = useCallback(
    async (isFirst) => {
      const res = await getJackpot()
      const resAmount = floor(get(res, "data.data", 0))
      runAnim(resAmount, isFirst)
    },
    [runAnim]
  )
  const [isOpenModalResult, setIsOpenModalResult] = useState(false)

  const onToggleResult = () => {
    setIsOpenModalResult(!isOpenModalResult)
  }

  useEffect(() => {
    const interval = setInterval(() => {
      onGetJackpot(false)
    }, INTERVAL_TIME)
    onGetJackpot(true)
    return () => clearInterval(interval)
  }, [onGetJackpot])

  const router = useRouter()

  return (
    <div className="banner-home">
      <div className="banner-image">
        <div className="banner-image-bg">
          <Image
            priority
            src={bannerPage}
            alt="Banner page"
            sizes="100vw"
            style={{
              width: "100%",
              height: "auto"
            }}
          />
        </div>

        <div className="banner-image-title text-uppercase">
          ARE YOU THE NEXT <span>BIG WINNER</span>?
        </div>
        <div className="banner-image-numbers">
          <div id="powerBlock_jackpot"></div>
        </div>
        {!!game.resultAtTimestamp && (
          <div className="banner-countdown">
            <div className="title">
              <ImagePriority
                src={
                  router.locale === "en"
                    ? "/images/next-draw-en.png"
                    : "/images/next-draw-vi.png"
                }
                alt="title next draw"
                width={423}
                height={49}
              />
            </div>
            <div className="desc">
              {getFormatDate(game.resultAtTimestamp, router.locale)}
            </div>
            <Countdown endTime={game.resultAtTimestamp} />

            <div className="slide-results">
              {isOpenModalResult && (
                <ModalResult
                  isOpen={isOpenModalResult}
                  game={game}
                  dialedGames={dialedGames}
                  toggle={onToggleResult}
                  centered
                />
              )}

              <div
                className="show-results show-results__text  cursor"
                onClick={onToggleResult}
              >
                {/* <div className="icon-arrow-right"></div> */}
                {/* <a href="/power-block-game">
                  <div className="icon">
                    <div className="icon-svg icon-games"></div>
                  </div>
                  <div className="content">
                    <span>333</span>
                  </div>
                  <div className="icon-new"></div>
                </a> */}
                <div className="icon">
                  <div className="icon-svg icon-games"></div>
                </div>
                <div className="text-results">
                  <div className="icon-arrow-right"></div>
                  {t("See results")}
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  )
}

export default memo(Banner)
