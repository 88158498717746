import clsx from "classnames"
import NextImage, { ImageProps } from "next/image"
import { FC, memo, useCallback, useMemo, useState } from "react"

import { IMG_DEFAULT, IMG_PLACE_HOLDER } from "@config/constants"
import styles from "./next-image.module.scss"

type PropsType = Omit<ImageProps, "alt"> & {
  isHandleError?: boolean
  imageError?: string
  alt?: string
}
const DEFAULT_SIZE = "(max-width: 768px) 100vw, (max-width: 1200px) 50vw, 50vw"
const NextImg: FC<PropsType> = (props) => {
  const {
    fill = true,
    src,
    width,
    height,
    loading,
    alt = "fxce-image-alt",
    // placeholder = 'blur',
    placeholder = "empty",
    blurDataURL = IMG_PLACE_HOLDER,
    sizes,
    priority = false,
    unoptimized,
    isHandleError = true,
    imageError
  } = props
  const [defaultImg, setDefaultImg] = useState<string>("")

  const imgSrc = useMemo(
    () => defaultImg || src || IMG_DEFAULT,
    [defaultImg, src]
  )

  const onError = useCallback(() => {
    if (isHandleError) {
      setDefaultImg(imageError || IMG_PLACE_HOLDER)
    }
  }, [imageError, isHandleError])

  // if fill == true  => reponsive by set width, height for class 'next-image-wrapper'
  // fill or width,height not combine
  const [_width, _height] = useMemo(() => {
    if (fill) {
      return [undefined, undefined]
    }
    return [width, height]
  }, [fill, width, height])

  return (
    <div
      className={clsx(
        "next-image-wrapper",
        fill && styles.nextImageFill,
        !fill && styles.nextImageInstrinc
      )}
    >
      <NextImage
        {...props}
        fill={fill}
        priority={priority}
        alt={alt}
        placeholder={placeholder}
        unoptimized={unoptimized}
        sizes={sizes || DEFAULT_SIZE}
        onError={onError}
        loading={loading}
        src={imgSrc}
        className="next-image"
        blurDataURL={blurDataURL}
        width={_width}
        height={_height}
      />
    </div>
  )
}

export default memo(NextImg)
