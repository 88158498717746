import React, { memo, useState, useEffect, useCallback } from "react"
import { useTranslation } from "next-i18next"

import moment from "moment"

const RED = "red"
const BLUE = "blue"
const SECOND_PER_MIN = 60
const SECOND_PER_HOUR = SECOND_PER_MIN * 60
const SECOND_PER_DAY = SECOND_PER_HOUR * 24
const INTERVAL_TIME = 1 * 1000

const TimeBlock = memo((props) => {
  const { type, number, label, enable = true } = props
  return (
    <div className={`block-container block-${type}  b-${enable}`}>
      <div className="block">
        {Math.floor(number / 10)}
        {number % 10}
      </div>
      <div className={`time-label b-${enable} fz-10`}>{label}</div>
    </div>
  )
})

const Countdown = memo((props) => {
  const { t } = useTranslation()
  const { endTime, onCompleteCount } = props

  const [isLoaded, setIsLoaded] = useState(false)
  const [isEnd, setIsEnd] = useState(false)
  const [totalSecond, setTotalSecond] = useState(4)

  useEffect(() => {
    const newTotalSecond = Math.max(
      0,
      moment(endTime, "DD/MM/YYYY HH:mm").diff(moment(), "seconds")
    )
    if (!!endTime && !isLoaded) {
      setIsLoaded(true)
      setTotalSecond(newTotalSecond)
    }
  }, [endTime, isLoaded])

  const updateTime = useCallback(() => {
    const diff = Math.max(0, endTime.diff(moment(), "seconds"))
    setTotalSecond(Math.max(0, endTime.diff(moment(), "seconds")))
    if (!isEnd && diff <= 0) {
      setIsEnd(true)
      // eslint-disable-next-line @typescript-eslint/no-unused-expressions
      onCompleteCount && onCompleteCount()
    }
  }, [endTime, isEnd, onCompleteCount])

  useEffect(() => {
    const interval = setInterval(() => {
      updateTime()
    }, INTERVAL_TIME)
    return () => clearInterval(interval)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isEnd])

  const days = Math.floor(totalSecond / SECOND_PER_DAY)
  const hours = Math.floor(totalSecond / SECOND_PER_HOUR) % 24
  const mins = Math.floor(totalSecond / SECOND_PER_MIN) % 60

  const seconds = totalSecond % 60
  const disableDays = days === 0
  const disableHours = disableDays && hours === 0
  const disableMins = disableHours && mins === 0
  const disableSeconds = disableMins && seconds === 0

  return (
    <div className="countdown">
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          marginRight: "10px"
        }}
      >
        <TimeBlock
          type={days <= 10 ? RED : BLUE}
          number={days}
          // label={t("Days")}
          label={t("Day", {
            count: Number(days)
          })}
          enable={!disableDays}
        />
        <div className={`twodot b-${!disableDays}`}>:</div>
        <TimeBlock
          type={disableDays && hours <= 10 ? RED : BLUE}
          number={hours}
          label={t("Hour", {
            count: Number(hours)
          })}
          enable={!disableHours}
        />
        <div className={`twodot b-${!disableHours}`}>:</div>
        <TimeBlock
          type={disableHours && mins <= 10 ? RED : BLUE}
          number={mins}
          label={t("Minute", {
            count: Number(mins)
          })}
          enable={!disableMins}
        />
        <div className={`twodot b-${!disableMins}`}>:</div>
        <TimeBlock
          type={disableSeconds ? RED : BLUE}
          number={seconds}
          label={t("Second", {
            count: Number(seconds)
          })}
          enable={!disableSeconds}
        />
      </div>
    </div>
  )
})

let thisTime = ""

const CountdownWrapper = (props) => {
  const { onCompleteCount } = props
  const [isLoaded, setIsLoaded] = useState(false)
  // const [show, setShow] = useState(false)
  const [endTime, setEndTime] = useState()
  const [interval, setInterval] = useState()

  const isBeforeNow = useCallback((time) => {
    const now = moment()
    return time.isBefore(now)
  }, [])

  const checkendTime = useCallback(() => {
    const { onEnd, _endTime } = props
    const time = moment(_endTime)

    if (isBeforeNow(time)) {
      // setShow(false)
      // eslint-disable-next-line @typescript-eslint/no-unused-expressions
      onEnd && onEnd()
      clearInterval(interval)
    }
  }, [props, isBeforeNow, interval])

  useEffect(() => {
    const intervalTime = setInterval(() => {
      checkendTime()
    }, INTERVAL_TIME)

    setInterval(intervalTime)

    return () => clearInterval(interval)
  }, [checkendTime, interval])

  useEffect(() => {
    const { endTime: _endTime } = props

    if ((_endTime && !isLoaded) || thisTime !== _endTime) {
      const time = moment(_endTime)
      thisTime = _endTime
      setIsLoaded(true)
      setEndTime(time)
      // setShow(!isBeforeNow(time))
    }
  }, [props, isBeforeNow, isLoaded, endTime])

  return (
    <div key={endTime}>
      {/* {!!show && ( */}
      <Countdown endTime={endTime} onCompleteCount={onCompleteCount} />
      {/* )} */}
    </div>
  )
}

export default memo(CountdownWrapper)
