import dynamic from "next/dynamic"

const ModalResultNoSSR: any = dynamic(() => import("./ModalResult"), {
  ssr: false
})

export default ModalResultNoSSR

// import ModalSSR from "./ModalResult"
// export default ModalSSR
